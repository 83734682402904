<template>
  <div v-on-viewport-enter.once="init" class="lazy-load-chart">
    <div :id="chartId" class="stock-price-chart"/>
  </div>
</template>

<script>
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import Stock from 'highcharts/modules/stock';
import NoData from 'highcharts/modules/no-data-to-display';
import chartOptions from '../../utility/charts/chart-options';
import OnViewportEnter from '../../directives/on-viewport-enter';

Stock(Highcharts);

const options = _.merge(
  _.cloneDeep(chartOptions),
  {
    chart: {
      spacingLeft: 24,
      spacingRight: 24,
      spacingBottom: 24
    }
  }
);

Highcharts.setOptions(options);
NoData(Highcharts);

export default {
  props: {
    remotePath: { type: String, required: true }
  },
  data() {
    return {
      // HACK set a custom random ID to fix an issue (maybe with turbolinks) where changing
      // between two stocks flashes the first stock price chart first before showing the current stock chart
      chartId: `stock-price-chart-${_.random(1000, 10000)}`,
      chart: null
    };
  },
  methods: {
    init() {
      this.initChart();
      this.chart.hideNoData();
      this.chart.showLoading();

      this.loadData().then((response) => {
        if (response.data) {
          this.chart.series[0].setData(response.data);
          this.chart.series[0].setName('Price');
        }
      }).catch(this.showError).then(() => { this.chart.hideLoading(); });
    },
    loadData() {
      return this.$axios.get(this.remotePath);
    },
    initChart() {
      this.chart = Highcharts.stockChart(
        this.chartId,
        {
          chart: {
            type: 'area'
          },
          navigator: {
            enabled: false
          },
          scrollbar: {
            enabled: false
          },
          series: [{}],
          rangeSelector: {
            selected: 4,
            buttons: [
              { type: 'month', count: 1, text: '1M' },
              { type: 'month', count: 3, text: '3M' },
              { type: 'month', count: 6, text: '6M' },
              { type: 'ytd', text: 'YTD' },
              { type: 'year', count: 1, text: '1Y' },
              { type: 'year', count: 3, text: '3Y' },
              { type: 'year', count: 5, text: '5Y' },
              { type: 'all', text: 'ALL' }
            ]
          },
          noData: {
            style: {
              color: '#d73e48', fontSize: '16px'
            }
          },
          yAxis: {
            labels: {
              enabled: false
            }
          },
          time: {
            // dates are passed in UTC, so use UTC instead of browser time settings
            // so that the dates are always shown the same regardless of the user time
            useUTC: true
          },
          tooltip: {
            split: false
          },
          plotOptions: {
            series: { states: { hover: { lineWidth: 2 } } }
          }
        }
      );
    },
    showError() {
      this.chart.showNoData('Error loading stock price data');
    }
  },
  directives: { OnViewportEnter }
};
</script>
