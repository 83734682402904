import initializeVue from './utility/initialize-vue';
import Search from './components/header/search.vue';
import ToggleButton from './components/elements/toggle-button.vue';

initializeVue('header', {
  data() {
    return {
      mobileMenuActive: false,
      searchFocused: false
    };
  },
  methods: {
    onSearchFocusIn() {
      this.searchFocused = true;
      // disable mobile menu if it is visible upon mobile search activation
      if (this.mobileMenuActive) this.mobileMenuActive = false;
    },
    onSearchFocusOut() {
      this.searchFocused = false;
    }
  },
  components: { Search, ToggleButton }
});
