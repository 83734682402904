<template>
  <div>
    <component :is="chartComponent" v-if="chartData" ref="chart" :data="chartData" :height="height"></component>
    <div v-else-if="loading" class="chart-loading columns" :style="{height: containerHeight}">
      <div class="column col-4 centered flex-centered">
        <progress class="progress" max="100"></progress>
      </div>
    </div>
    <div v-else-if="error" class="toast toast-error">Error loading data</div>
  </div>
</template>

<script>
import Chart from './chart.vue';
import BalanceChart from './balance-chart.vue';

export default {
  props: {
    remotePath: { type: String, required: true },
    height: { type: String, default: '50%' },
    params: { type: Object, default: () => {} },
    chartComponent: { type: String, default: 'chart' }
  },
  data() {
    return {
      isInitialized: false,
      loading: false,
      error: false,
      chartData: null
    };
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.error = false;
      this.chartData = null;

      try {
        const response = await this.$axios.get(this.remotePath, { params: this.params });
        this.chartData = response.data;
      } catch (_error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    init() {
      if (!this.isInitialized) {
        this.loadData();
        this.isInitialized = true;
      }
    }
  },
  computed: {
    containerHeight() {
      if (this.height) {
        if (this.height.indexOf('px') !== -1) {
          return this.height;
        }
        if (this.height.indexOf('%') !== -1 && this.$el.clientWidth) {
          return `${((parseInt(this.height, 10) * this.$el.clientWidth) / 100)}px`;
        }
      }
      return '400px';
    }
  },
  components: { Chart, BalanceChart }
};
</script>
